var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"menu-screen","translate":"no"}},[_c('Loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"height":40,"loader":"spinner","color":"#003340"}}),((_vm.store) || (_vm.store && _vm.menu && _vm.menu.restaurant && !_vm.menu.restaurant.goToMenuFromServicesScreen) || _vm.tableHasRedirectBackLink || _vm.isPdaAndHasID || _vm.redirectBackTo!=null)?_c('BackToServices',{class:{'left-10':(_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width>1018 },attrs:{"table":_vm.tableId,"storeName":_vm.storeName,"isApplication":_vm.isApplication,"redirectBackTo":_vm.redirectBackTo,"hasBoxShadow":_vm.menu.restaurant && _vm.menu.restaurant.type == 'INFO',"restaurantType":_vm.menu.restaurant.type,"amountOfDepartments":_vm.store.amountOfDepartments,"isPda":_vm.isPda || _vm.isPdaAndHasID,"id":"back-to-services"}}):_vm._e(),(
      _vm.$screen.width <= 1018 &&
      _vm.categoriesToShow.length != 0 &&
      _vm.menu.restaurant &&
      (_vm.menu.restaurant.type == 'MENU' || _vm.menu.restaurant.type == 'REQUESTS')
    )?_c('CategoriesNavbar',{attrs:{"id":"categories-navbar","multipleServices":_vm.store || (_vm.store && _vm.menu && _vm.menu.restaurant && !_vm.menu.restaurant.goToMenuFromServicesScreen) || _vm.tableHasRedirectBackLink || _vm.isPdaAndHasID || _vm.redirectBackTo!=null}}):_vm._e(),(!((_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width > 1018))?_c('div',{attrs:{"id":"banner"}},[(_vm.store)?_c('img',{attrs:{"id":"banner-image","src":("" + _vm.bannerImage),"alt":""}}):_vm._e(),_c('div',{attrs:{"id":"store-info"}},[_c('Store',{attrs:{"id":"store","store":_vm.store,"shouldShowMenu":false}})],1)]):_vm._e(),_c('div',{class:{'desktop-pda-container': ((_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width > 1018)}},[(_vm.$screen.width > 1018 && _vm.isViewOnly !== true && (_vm.isPda || _vm.isPdaAndHasID) && _vm.menu)?_c('CartSummaryPdaDesktop',{attrs:{"cartItems":_vm.cartItems,"redirectBackTo":_vm.redirectBackTo,"totalCost":_vm.totalCost,"table":_vm.menu.table,"service":_vm.menu.restaurant,"storeId":_vm.storeId,"isServiceOpen":_vm.isServiceOpen},on:{"go-to-cart-screen":_vm.goToCartScreen,"on-successful-order":_vm.adaptStockAmountInMenu}}):_vm._e(),_c('div',{class:{'non-pda-body': !(_vm.isPda || _vm.isPdaAndHasID) || ((_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width<=1018), 
             'desktop-pda-body': ((_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width > 1018)}},[(
        _vm.$screen.width > 1018 &&
        _vm.menu.restaurant &&
        (_vm.menu.restaurant.type == 'MENU' || _vm.menu.restaurant.type == 'REQUESTS') && !(_vm.isPda || _vm.isPdaAndHasID))?_c('Categories',{attrs:{"isPda":_vm.isPda || _vm.isPdaAndHasID,"categoriesType":"menu"}}):_vm._e(),(
        _vm.$screen.width > 1018 &&
        _vm.menu.restaurant &&
        (_vm.menu.restaurant.type == 'MENU' || _vm.menu.restaurant.type == 'REQUESTS') && (_vm.isPda || _vm.isPdaAndHasID))?_c('PdaDesktopCategories',{attrs:{"isPda":_vm.isPda || _vm.isPdaAndHasID,"categoriesType":"menu"}}):_vm._e(),_c('div',{class:{'width-100':(_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width>1018 },attrs:{"id":"food-items"}},[(_vm.showContestRibbon)?_c('ContestRibbon'):_vm._e(),(_vm.store && !_vm.showFluterBanner && _vm.menu.restaurant.names.translation[_vm.language].customHeaderBannerText!=null && _vm.menu.restaurant.names.translation[_vm.language].customHeaderBannerText!='')?_c('CustomHeaderBanner',{attrs:{"restaurantNames":_vm.menu.restaurant.names,"showFluterBanner":_vm.showFluterBanner}}):_vm._e(),(_vm.showFluterBanner)?_c('FluterDiscountBanner',{attrs:{"deliveryType":_vm.deliveryType,"isApplication":_vm.isApplication,"restaurantNames":_vm.menu.restaurant.names,"storeNames":_vm.store.names,"serviceStartTime":_vm.serviceStartTime,"serviceEndTime":_vm.serviceEndTime,"pricingPackage":_vm.store.pricingPackage,"isServiceOpen":_vm.isServiceOpen,"shouldShowOnlyScheduledOrders":_vm.shouldShowOnlyScheduledOrders,"isOpenForScheduledButNotInCurrentTime":_vm.isOpenForScheduledButNotInCurrentTime,"scheduledOrdersStartTime":_vm.scheduledOrdersStartTime,"scheduledOrdersEndTime":_vm.scheduledOrdersEndTime,"isDelayedResponse":_vm.menu.restaurant.delayedResponse,"minimumPriceForDeliveryDiscount":_vm.minimumPriceForDeliveryDiscount,"isRoomServiceCharged":_vm.isRoomServiceCharged,"typeOfRoomServiceCharge":_vm.typeOfRoomServiceCharge,"roomServiceCharge":_vm.roomServiceCharge}}):_vm._e(),(_vm.menu && _vm.menu.restaurant && (_vm.menu.restaurant.type == 'MENU' || _vm.menu.restaurant.type == 'REQUESTS'))?_c('div',{staticClass:"menu minHeight",class:{'add-padding-bottom': _vm.$screen.width > 1018,
                   'menu-non-pda-desktop': _vm.$screen.width > 1300 && !(_vm.isPda || _vm.isPdaAndHasID),
                   'menu-pda-desktop': _vm.$screen.width > 1018 && (_vm.isPda || _vm.isPdaAndHasID)},attrs:{"id":"menu-container"}},_vm._l((_vm.categoriesToShow),function(category){return _c('div',{key:category.id,attrs:{"id":category.names.translation['ENG'].name.replace(' ', '-')}},[_c('CategoryProducts',{attrs:{"category":category,"membershipEnabledForRestaurant":_vm.menu.restaurant.membershipEnabled,"userHasMembership":_vm.userHasMembership,"membershipPricesVisible":_vm.menu.restaurant.membershipPricesVisible,"hideDescription":_vm.menu.restaurant.descriptionHiddenOfItemsOnPda,"hidePhotos":_vm.menu.restaurant.photosHiddenOfItemsOnPda,"storeIsHotel":_vm.store.hotel,"happyHourDiscountPercentage":0,"currentGreekDate":_vm.currentGreekDate,"isInteractiveCatalogue":_vm.isInteractiveCatalogue,"isInHappyHour":false,"isPda":_vm.isPda || _vm.isPdaAndHasID}})],1)}),0):(_vm.menu.restaurant && _vm.menu.restaurant.type == 'INFO')?_c('div',{staticClass:"menu",attrs:{"id":"menu-container"}},[_c('h3',{staticClass:"department-info-title"},[_vm._v(_vm._s(_vm.menu.restaurant.names.translation[_vm.language].name))]),_c('div',{class:{
            hasBorderBottom: true,
            addMargin: true,
          }},[_c('div',{staticClass:"message-container",staticStyle:{"display":"flex","flex-direction":"column"},domProps:{"innerHTML":_vm._s(_vm.menu.restaurant.names.translation[_vm.language].infoText)}})])]):_vm._e()],1)],1)],1),(_vm.$screen.width > 1018 && _vm.isViewOnly !== true)?_c('div',{class:{
      openCartDetails: _vm.isCartPreviewOpen,
      closeCartDetails: !_vm.isCartPreviewOpen && _vm.hasCartPreviewOpened
    },attrs:{"id":"current-order"}},[_c('div',{attrs:{"id":"cart-desktop-header"}},[_c('div',{attrs:{"id":"cart-desktop-top-header-container"}},[_c('h4',{attrs:{"id":"to-kalathi-mou"}},[_vm._v(_vm._s(_vm.labels.cart.myCart[_vm.language]))]),_c('font-awesome-icon',{attrs:{"icon":"times","id":"close-cart-summary-icon"},on:{"click":_vm.closeCart}})],1),_c('div',{attrs:{"id":"total-cost"}},[_c('h6',[_vm._v(" "+_vm._s(_vm.labels.cart.total[_vm.language])+": "),_c('span',[_vm._v(_vm._s(_vm.totalCost)+"€")])])])]),_vm._l((_vm.cartItems),function(item,index){return _c('CartItem',{key:item.id,attrs:{"item":item,"index":index,"deleteIcon":"trash"}})}),_c('div',{attrs:{"id":"send-order"}},[_c('Button',{class:{ grayOut: _vm.cartSize == 0 },attrs:{"id":"send-order-button"},on:{"click":_vm.goToCartScreen}},[_vm._v(_vm._s(_vm.labels.cart.completeOrder[_vm.language]))])],1)],2):_vm._e(),_c('div',{attrs:{"id":"upper-bar"}},[(!_vm.isApplication && !((_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width > 1018))?_c('div',{attrs:{"id":"logo-container"}},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"upper-bar-row-container"},[(_vm.isAbleToChangeLanguage)?_c('LanguageSelect',{class:{ 'language-position-in-login': _vm.isLoggedIn, 'language-position-pda-desktop': (_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width > 1018},attrs:{"id":"language-select"}}):_vm._e(),(_vm.isLoggedIn && _vm.tableType!='SELF_SERVICE_POS')?_c('ProfileDropdown',{class:{ 'profile-dropdown-with-lang-select': _vm.isAbleToChangeLanguage,
                  'profile-dropdown-without-lang-select': !_vm.isAbleToChangeLanguage,
                  'profile-dropdown-pda-desktop': (_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width > 1018},attrs:{"id":"profile-dropdown","isApplication":_vm.isApplication}}):(_vm.shouldShowEnterPrompt && !_vm.isLoggedIn)?_c('EnterPrompt',{class:{ 'enter-prompt-with-lang-select': _vm.isAbleToChangeLanguage,
                  'enter-prompt-without-lang-select': !_vm.isAbleToChangeLanguage,
                  'enter-prompt-pda-desktop': (_vm.isPda || _vm.isPdaAndHasID) && _vm.$screen.width > 1018 },attrs:{"id":"enter-prompt"},on:{"enter-clicked":_vm.initiateEntrance}}):_vm._e(),(_vm.$screen.width > 1018 && _vm.isViewOnly !== true && _vm.isInteractiveCatalogue && _vm.cartSize != 0 && !(_vm.isPda || _vm.isPdaAndHasID))?_c('div',{staticClass:"bar-icon-background",on:{"click":_vm.goToCartScreenOrOpenCartSummary}},[_c('Cart',{attrs:{"itemCount":_vm.cartSize,"id":"cart"}})],1):_vm._e()],1)]),_c('div',{class:{
      mask:
        _vm.isModalOpen ||
        _vm.areMobileCategoriesOpen ||
        _vm.isEntranceOpen ||
        _vm.isLanguageModalOpen ||
        _vm.isContestModalOpen ||
        _vm.isCategoriesModalOpen
    },on:{"click":_vm.onMaskClick}}),(
      _vm.$screen.width <= 1018 &&
      _vm.isViewOnly !== true &&
      _vm.menu.restaurant &&
      (_vm.menu.restaurant.type == 'MENU' || _vm.menu.restaurant.type == 'REQUESTS')
      && _vm.isInteractiveCatalogue
    )?_c('div',{class:{
      showMobileCart: false,
      hideMobileCart: false,
    },attrs:{"id":"mobile-cart"}},[_c('Button',{class:{ grayOut: _vm.cartSize == 0, activeJump: _vm.cartSize > 0, 
                      scheduledOrdersOnlyEmptyCartGR: _vm.shouldShowOnlyScheduledOrders && _vm.language=='GRE' && _vm.cartSize == 0,
                      scheduledOrdersOnlyEmptyCartENG: _vm.shouldShowOnlyScheduledOrders && _vm.language=='ENG' && _vm.cartSize == 0,
                      scheduledOrdersOnlyFullCartGR: _vm.shouldShowOnlyScheduledOrders && _vm.language=='GRE' && _vm.cartSize > 0,
                      scheduledOrdersOnlyFullCartENG: _vm.shouldShowOnlyScheduledOrders && _vm.language=='ENG' && _vm.cartSize > 0  },attrs:{"loading":_vm.isLoading,"id":"mobile-send-order-button"},on:{"click":_vm.goToCartScreen}},[_c('div',{attrs:{"id":"mobile-cart-button-content"}},[_c('div',{class:{ notRotate: _vm.reverseRotate, goRight: _vm.reverseRotate },attrs:{"id":"cart-size"}},[_c('h6',[_vm._v(_vm._s(_vm.cartSize))])]),_c('div',{class:{ notRotate: _vm.reverseRotate},attrs:{"id":"mobile-button-text"}},[_c('h5',[_vm._v(_vm._s(_vm.mobileButtonText))])]),(!_vm.shouldShowOnlyScheduledOrders)?_c('div',{class:{
            goLeft: _vm.reverseRotate
          },attrs:{"id":"mobile-cost"}},[_c('h6',{class:{ notRotate: _vm.reverseRotate }},[_vm._v(_vm._s(_vm.totalCost)+"€")])]):_vm._e()])])],1):_vm._e(),_c('div',{staticClass:"custom-footer-message"},[(_vm.store && _vm.menu.restaurant.names.translation[_vm.language].customFooterBannerText!=null && _vm.menu.restaurant.names.translation[_vm.language].customFooterBannerText!='')?_c('CustomBottomBanner',{attrs:{"restaurantNames":_vm.menu.restaurant.names}}):_vm._e()],1),(_vm.store && _vm.store.showFluterFooter)?_c('Footer'):(_vm.menu && _vm.menu.restaurant)?_c('PoweredByFooter',{attrs:{"menuType":_vm.menu.restaurant.type}}):_vm._e(),(_vm.isCategoriesModalOpen)?_c('CategoriesModal',{attrs:{"categories":_vm.categoriesToShow,"isPda":_vm.isPda || _vm.isPdaAndHasID}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"id":"link-to-landing","href":"/"}},[_c('img',{attrs:{"id":"logo","src":require("../../assets/Full_logo-Black.svg"),"alt":""}})])}]

export { render, staticRenderFns }