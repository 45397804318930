<template>
  <div v-if="category">
    <div id="category-label-container">
      <h3 class="category-label">
        {{ getCategoryName(category)}}
        <span
          id="category-schedule"
          style="margin-left: 5px"
          v-if="category.startTime && category.endTime"
        >
          ({{ formattedTime(category.startTime) }} -
          {{ formattedTime(category.endTime) }})
        </span>
      </h3>
      <div v-if="hasCategoryDescription(category)" class="category-description-container">
        <font-awesome-icon class="category-description-icon" icon="info" />
        <h4 id="category-description">{{ getCategoryDescription(category) }}</h4>
      </div>
      <div
        v-if="category.maxProductsPerCartForThisCategory!=null && category.maxProductsPerCartForThisCategory >0 "
        class="category-description-container"
      >
        <font-awesome-icon
          class="category-description-icon"
          icon="sliders-h"
          style="margin-top: 5px !important;"
        />
        <h4
          id="category-description"
        >{{ labels.menu.selectUpTo[language] }} {{category.maxProductsPerCartForThisCategory}} {{ labels.menu.productsFromCategory[language] }}</h4>
      </div>
    </div>

    <div :class="{ 'two-items-flex-container': isPda && $screen.width > 640 }">
      <div
        class="left-right-margins hasBorderBottom"
        :class="{'flex-item-42 hasDarkerBorderBottom': isPda && $screen.width > 640,
               'hasNoBorderBottom': isPda && $screen.width > 1018,
               'max-width-42': isPda && $screen.width > 1018,
               'flex-item-27': isPda && $screen.width > 1500,
               'max-width-47': isPda && $screen.width <= 1018 && $screen.width > 640 }"
        v-for="item in availableProducts"
        v-bind:key="item.id"
      >
        <MenuItem
          :isCategoryOpen="isCategoryOpen"
          :item="item"
          :membershipEnabledForRestaurant="membershipEnabledForRestaurant"
          :membershipPricesVisible="membershipPricesVisible"
          :isInteractiveCatalogue="isInteractiveCatalogue"
          :userHasMembership="userHasMembership"
          :storeIsHotel="storeIsHotel"
          :hideDescription="hideDescription"
          :hidePhotos="hidePhotos"
          :isPda="isPda"
        ></MenuItem>
      </div>
      <div
        v-if="unavailableProducts.length > 0 && !clickedToSeeUnavailable"
        class="left-right-margins"
        :class="{'flex-item-42': isPda && $screen.width > 640,
               'max-width-42': isPda && $screen.width > 1018,
               'flex-item-27': isPda && $screen.width > 1500,
               'max-width-47': isPda && $screen.width <= 1018 && $screen.width > 640 }"
      >
        <div class="unavailable-items-container" @click="showUnavailableItems">
          <div class="unavailable-items-button">
            <div class="unaivailble-items-title">
              <h3>{{unavailableProducts.length}} {{labels.menu.unavailanbleItems[language]}}</h3>
              <h4
                v-if="!isCategoryOpen"
              >{{labels.menu.servingHours[language]}} {{category.startTime.slice(0, -3)}}-{{category.endTime.slice(0, -3)}}</h4>
            </div>
            <div class="unaivailble-items-description">
              <div>{{labels.menu.seeUnavailableItems[language]}}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="unavailableProducts.length > 0 && clickedToSeeUnavailable"
        :class="{ 'two-items-flex-container-unavailable': isPda && $screen.width > 640 }"
      >
        <div
          class="left-right-margins hasBorderBottom"
          :class="{'flex-item-42': isPda && $screen.width > 640,
                 'max-width-42': isPda && $screen.width > 1018,
                 'flex-item-27': isPda && $screen.width > 1500,
                 'max-width-47': isPda && $screen.width <= 1018 && $screen.width > 640 }"
          v-for="item in unavailableProducts"
          v-bind:key="item.id"
        >
          <MenuItem
            :isCategoryOpen="isCategoryOpen"
            :item="item"
            :membershipEnabledForRestaurant="membershipEnabledForRestaurant"
            :membershipPricesVisible="membershipPricesVisible"
            :storeIsHotel="storeIsHotel"
            :happyHourDiscountPercentage="happyHourDiscountPercentage"
            :isInHappyHour="isInHappyHour"
            :hideDescription="hideDescription"
            :hidePhotos="hidePhotos"
            :isPda="isPda"
          ></MenuItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
export default {
  name: "CategoryProducts",
  components: {
    MenuItem,
  },
  data() {
    return {
      clickedToSeeUnavailable: false,
    };
  },
  props: ["category", "membershipEnabledForRestaurant", "membershipPricesVisible", "userHasMembership", "storeIsHotel", "happyHourDiscountPercentage", "isInHappyHour", "currentGreekDate", "isInteractiveCatalogue", "isPda", "hideDescription", "hidePhotos"],
  computed: {
    availableProducts() {
      if (!this.isCategoryOpen) {
        return [];
      };
      return this.category.products.filter((product) => product.available);
    },

    unavailableProducts() {
      if (!this.isCategoryOpen) {
        return this.category.products;
      }
      return this.category.products.filter((product) => !product.available);
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    isCategoryOpen() {
      if (!this.category.startTime || !this.category.endTime || this.currentGreekDate == '' || this.currentGreekDate == undefined) {
        return true;
      }

      let currentHour =
        this.currentGreekDate.getHours() > 9
          ? this.currentGreekDate.getHours()
          : `0${this.currentGreekDate.getHours()}`;
      let currentMinute =
        this.currentGreekDate.getMinutes() > 9
          ? this.currentGreekDate.getMinutes()
          : `0${this.currentGreekDate.getMinutes()}`;
      let currentSecond =
        this.currentGreekDate.getSeconds() > 9
          ? this.currentGreekDate.getSeconds()
          : `0${this.currentGreekDate.getSeconds()}`;
      let currentTime = `${currentHour}:${currentMinute}:${currentSecond}`;

      let isCategoryOpen = true;

      if (this.category.startTime < this.category.endTime) {
        isCategoryOpen =
          this.category.startTime < currentTime &&
          this.category.endTime > currentTime;
      } else {
        isCategoryOpen =
          this.category.startTime < currentTime ||
          this.category.endTime > currentTime;
      }

      return isCategoryOpen;
    }
  },
  methods: {
    getCategoryName(category) {
      if (this.selectedExtraLanguage != null && category.names.translation[this.selectedExtraLanguage] && category.names.translation[this.selectedExtraLanguage].name != null && category.names.translation[this.selectedExtraLanguage].name.trim().length != 0) {
        return category.names.translation[this.selectedExtraLanguage].name;
      }
      return category.names.translation[this.language].name;
    },

    getCategoryDescription(category) {
      if (this.selectedExtraLanguage != null && category.names.translation[this.selectedExtraLanguage] && category.names.translation[this.selectedExtraLanguage].description != null && category.names.translation[this.selectedExtraLanguage].description.trim().length != 0) {
        return category.names.translation[this.selectedExtraLanguage].description;
      }
      return category.names.translation[this.language].description;
    },

    formattedTime: function (time) {
      return time.slice(0, -3);
    },

    hasCategoryDescription: function (category) {
      return (
        category.names.translation[this.language].description &&
        category.names.translation[this.language].description.trim() != ""
      );
    },

    showUnavailableItems() {
      this.clickedToSeeUnavailable = true;
    },
  },
};
</script>

<style scoped>
#category-label-container {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.75);
  margin: 3% 4% 3% 4%;
  padding-bottom: 2%;
}

.category-label {
  color: #1f1f1f;
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 0;
}

#category-schedule {
  margin-top: 2px;
  font-size: 14px;
  align-self: center;
}

.category-description-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.category-description-icon {
  align-self: flex-start;
  margin-right: 3px;
  margin-top: 4px;
  font-size: 12px;
  color: rgb(121, 121, 121);
}

#category-description {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 600 !important;
  color: rgb(109, 109, 109);
}

.hasBorderBottom {
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
}

.hasDarkerBorderBottom {
  border-bottom: 1px solid rgba(116, 135, 150, 0.4) !important;
}

.hasNoBorderBottom {
  border-bottom: none !important;
}

.left-right-margins {
  margin-left: 4%;
  margin-right: 4%;
}

.two-items-flex-container {
  display: flex;
  flex-wrap: wrap;
}

.two-items-flex-container-unavailable {
  display: flex;
  flex-wrap: wrap;
  min-width: 50%;
  width: 100%;
}

.flex-item-42 {
  flex: 42%;
}

.max-width-42 {
  max-width: 42%;
}

.flex-item-27 {
  flex: 27%;
  max-width: 27%;
  margin-left: 3% !important;
  margin-right: 3% !important;
}

.max-width-47 {
  max-width: 47%;
}

.unavailable-items-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2% 0 2% 0;
  cursor: pointer;
  z-index: 1;
  background-color: rgba(180, 180, 180, 0.2);
  padding-left: 5px;
  border-radius: 10px;
}

.unavailable-items-button {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #414551;
  font-weight: 500;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.unavailable-items {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  background-color: #bdbdbd;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
}

.unaivailble-items-title > h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.unaivailble-items-title > h4 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.unaivailble-items-description {
  color: gray;
  font-size: 13px;
  font-weight: 400;
  padding-right: 15%;
  white-space: pre-wrap;
}

@media screen and (max-width: 1018px) {
  #category-label-container {
    margin: 3% 16px 3% 16px;
  }

  .category-label {
    width: 98%;
    font-size: 26px;
  }

  #category-schedule {
    font-size: 12px;
  }

  #category-description {
    margin-top: 2px;
    font-size: 13px;
  }

  .unavailable-items-container {
    margin: 4% 0 4% 0;
  }

  .unavailable-items-button {
    padding-right: 3%;
    padding-left: 1%;
  }

  .unavailable-items {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
  }

  .unaivailble-items-title > h3 {
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
  }

  .unaivailble-items-title > h4 {
    font-size: 13px;
    font-weight: 500;
    padding-right: 5px;
  }

  .unaivailble-items-description {
    font-size: 12px;
    font-weight: normal;
  }
  .left-right-margins {
    margin-right: 16px !important;
    margin-left: 16px !important;
  }
}

@media screen and (max-width: 753px) {
  .category-label {
    font-size: 21px;
  }
}
</style>