var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category)?_c('div',[_c('div',{attrs:{"id":"category-label-container"}},[_c('h3',{staticClass:"category-label"},[_vm._v(" "+_vm._s(_vm.getCategoryName(_vm.category))+" "),(_vm.category.startTime && _vm.category.endTime)?_c('span',{staticStyle:{"margin-left":"5px"},attrs:{"id":"category-schedule"}},[_vm._v(" ("+_vm._s(_vm.formattedTime(_vm.category.startTime))+" - "+_vm._s(_vm.formattedTime(_vm.category.endTime))+") ")]):_vm._e()]),(_vm.hasCategoryDescription(_vm.category))?_c('div',{staticClass:"category-description-container"},[_c('font-awesome-icon',{staticClass:"category-description-icon",attrs:{"icon":"info"}}),_c('h4',{attrs:{"id":"category-description"}},[_vm._v(_vm._s(_vm.getCategoryDescription(_vm.category)))])],1):_vm._e(),(_vm.category.maxProductsPerCartForThisCategory!=null && _vm.category.maxProductsPerCartForThisCategory >0 )?_c('div',{staticClass:"category-description-container"},[_c('font-awesome-icon',{staticClass:"category-description-icon",staticStyle:{"margin-top":"5px !important"},attrs:{"icon":"sliders-h"}}),_c('h4',{attrs:{"id":"category-description"}},[_vm._v(_vm._s(_vm.labels.menu.selectUpTo[_vm.language])+" "+_vm._s(_vm.category.maxProductsPerCartForThisCategory)+" "+_vm._s(_vm.labels.menu.productsFromCategory[_vm.language]))])],1):_vm._e()]),_c('div',{class:{ 'two-items-flex-container': _vm.isPda && _vm.$screen.width > 640 }},[_vm._l((_vm.availableProducts),function(item){return _c('div',{key:item.id,staticClass:"left-right-margins hasBorderBottom",class:{'flex-item-42 hasDarkerBorderBottom': _vm.isPda && _vm.$screen.width > 640,
             'hasNoBorderBottom': _vm.isPda && _vm.$screen.width > 1018,
             'max-width-42': _vm.isPda && _vm.$screen.width > 1018,
             'flex-item-27': _vm.isPda && _vm.$screen.width > 1500,
             'max-width-47': _vm.isPda && _vm.$screen.width <= 1018 && _vm.$screen.width > 640 }},[_c('MenuItem',{attrs:{"isCategoryOpen":_vm.isCategoryOpen,"item":item,"membershipEnabledForRestaurant":_vm.membershipEnabledForRestaurant,"membershipPricesVisible":_vm.membershipPricesVisible,"isInteractiveCatalogue":_vm.isInteractiveCatalogue,"userHasMembership":_vm.userHasMembership,"storeIsHotel":_vm.storeIsHotel,"hideDescription":_vm.hideDescription,"hidePhotos":_vm.hidePhotos,"isPda":_vm.isPda}})],1)}),(_vm.unavailableProducts.length > 0 && !_vm.clickedToSeeUnavailable)?_c('div',{staticClass:"left-right-margins",class:{'flex-item-42': _vm.isPda && _vm.$screen.width > 640,
             'max-width-42': _vm.isPda && _vm.$screen.width > 1018,
             'flex-item-27': _vm.isPda && _vm.$screen.width > 1500,
             'max-width-47': _vm.isPda && _vm.$screen.width <= 1018 && _vm.$screen.width > 640 }},[_c('div',{staticClass:"unavailable-items-container",on:{"click":_vm.showUnavailableItems}},[_c('div',{staticClass:"unavailable-items-button"},[_c('div',{staticClass:"unaivailble-items-title"},[_c('h3',[_vm._v(_vm._s(_vm.unavailableProducts.length)+" "+_vm._s(_vm.labels.menu.unavailanbleItems[_vm.language]))]),(!_vm.isCategoryOpen)?_c('h4',[_vm._v(_vm._s(_vm.labels.menu.servingHours[_vm.language])+" "+_vm._s(_vm.category.startTime.slice(0, -3))+"-"+_vm._s(_vm.category.endTime.slice(0, -3)))]):_vm._e()]),_c('div',{staticClass:"unaivailble-items-description"},[_c('div',[_vm._v(_vm._s(_vm.labels.menu.seeUnavailableItems[_vm.language]))])])])])]):_vm._e(),(_vm.unavailableProducts.length > 0 && _vm.clickedToSeeUnavailable)?_c('div',{class:{ 'two-items-flex-container-unavailable': _vm.isPda && _vm.$screen.width > 640 }},_vm._l((_vm.unavailableProducts),function(item){return _c('div',{key:item.id,staticClass:"left-right-margins hasBorderBottom",class:{'flex-item-42': _vm.isPda && _vm.$screen.width > 640,
               'max-width-42': _vm.isPda && _vm.$screen.width > 1018,
               'flex-item-27': _vm.isPda && _vm.$screen.width > 1500,
               'max-width-47': _vm.isPda && _vm.$screen.width <= 1018 && _vm.$screen.width > 640 }},[_c('MenuItem',{attrs:{"isCategoryOpen":_vm.isCategoryOpen,"item":item,"membershipEnabledForRestaurant":_vm.membershipEnabledForRestaurant,"membershipPricesVisible":_vm.membershipPricesVisible,"storeIsHotel":_vm.storeIsHotel,"happyHourDiscountPercentage":_vm.happyHourDiscountPercentage,"isInHappyHour":_vm.isInHappyHour,"hideDescription":_vm.hideDescription,"hidePhotos":_vm.hidePhotos,"isPda":_vm.isPda}})],1)}),0):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }